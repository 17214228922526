import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Lokesh Khandelwal</span> from{" "}
            <span className="purple">Bangalore, India.</span>
            <br />
            I am currently working as a <span className="purple"> Software Developer</span> at{" "}
            <span className="purple">ZAGENO Inc.</span>
            <br />
            I completed my B.E. in Computer Science &amp; Engineering at{" "}
            <span className="purple">RNSIT, Bangalore</span> in 2024.
            <br />
            <br />
            My journey includes valuable experience as a Software Developer Intern at{" "}
            <span className="purple">Nokia Solutions and Networks</span> in Bengaluru, where I contributed to dynamic projects and sharpened my skills in frontend development.
            <br />
            <br />
            Apart from coding, here are a few activities I love:
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> High Intensity Workouts
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>
          <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"
          </p>
          <footer className="blockquote-footer">Lokesh</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;